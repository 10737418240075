.cmp-teasers-stage {
  margin-left: var(--grid-anti-margin);
  width: calc(100% + (2 * var(--grid-margin)));

  &__image {
    .cmp-responsive-image.replaced img {
      aspect-ratio: 3/1;
      position: relative;

      @media only screen and (--breakpoint-down-md) {
        aspect-ratio: 16/9;
      }
    }
  }

  &__teasers {
    display: flex;
    gap: var(--spacing-lg);
    padding: 0 var(--spacing-lg);
    margin-top: -5%;
    position: relative;
    transition: margin-left 0.3s ease-in-out;
  }

  &__teaser {
    background-color: var(--color-gray-50);
    border-radius: var(--border-radius-m);
    padding: var(--spacing-md);
    min-width: toRem(212px);

    &-title {
      margin-bottom: var(--spacing-md);
    }

    &-button {
      background-color: var(--color-gray-900);
      color: var(--color-white);
      border-radius: var(--border-radius-xs);
      display: flex;
      margin-left: auto;
      width: fit-content;

      &::before {
        font-family: "custom_materialicons", sans-serif;
        content: var(--icon-keyboard_arrow_right);
        font-weight: var(--font-weight-bold);
        font-size: toRem(24px);
      }
    }
  }

  &__navigation {
    display: none;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-md);
    margin-top: var(--spacing-lg);

    &--visible {
      display: flex;
    }

    &-button {
      background-color: var(--color-gray-100);
      color: var(--color-gray-900);
      border-radius: var(--border-radius-xs);
      display: flex;
      width: fit-content;
      font-size: toRem(32px);

      &::before {
        font-family: "custom_materialicons", sans-serif;
        font-weight: var(--font-weight-bold);
      }

      &--previous {
        &::before {
          content: var(--icon-keyboard_arrow_left);
        }
      }

      &--next {
        &::before {
          content: var(--icon-keyboard_arrow_right);
        }
      }
    }
  }
}

/**
 * Text - Component
 */
.text {
  position: relative;
}

.cmp-text {
  padding-top: var(--spacing-md);

  &__title {
    @include bold-3xl();

    margin-bottom: var(--spacing-xxs);
    display: block;
  }

  &__subtitle {
    @include bold-xl();

    margin-bottom: var(--spacing-sm);
  }

  &__paragraph {
    margin-top: var(--spacing-md);

    .cmp-text__table-wrapper__relative {
      margin-top: var(--spacing-md);
    }
  }

  &--has-separation-line {
    &::after {
      position: absolute;
      left: 50%;
      right: var(--spacing-sm);
      top: 0;
      border-top: var(--border-m) solid var(--color-gray-50);
      content: '';
    }

    &::before {
      position: absolute;
      right: 50%;
      left: var(--spacing-sm);
      top: 0;
      border-top: var(--border-m) solid var(--color-primary-500);
      content: '';
    }
  }
}

.cmp-text a {
  display: inline-block;
  color: var(--color-primary-500);
  text-decoration: underline;
  transition: transform 0.1s;
}

.cmp-text li > a {
  display: inline;
}

.cmp-text ul,
.cmp-text ol {
  padding-left: var(--spacing-xl);
}

/* stylepolicy - table links with icon */
.cmp-text--table-link-icon-new-window table a {
  @include bold-md();

  text-decoration: none;
  vertical-align: middle;
  display: inline-flex;

  &::before {
    content: var(--icon-openinnewwindow);
    font-family: var(--icon-font);
    display: inline-flex;
    margin-right: var(--spacing-xxs);
    vertical-align: middle;
    font-weight: var(--font-weight);
    font-size: var(--font-size-icon-sm);
  }
}

.cmp-text a:hover {
  color: var(--color-gray-900);
}

.cmp-text li {
  margin-bottom: var(--spacing-md);
}

.cmp-text blockquote {
  font-size: var(--font-size-h3);
  line-height: var(--line-height-h3);
  margin-top: var(--spacing-4xl);
  position: relative;
  text-align: center;
}

.cmp-text blockquote::before {
  @include regular-8xl();

  font-family: 'custom_materialicons', sans-serif;
  content: var(--icon-format_quote);
  color: var(--color-gray-100);
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  top: calc(-1 * var(---spacing-xl));
  z-index: -1;
  transform: rotate(180deg);
}

/* table */
.cmp-text th,
.cmp-text td {
  @include regular-lg;

  border: var(--spacing-xxxs) solid var(--color-gray-100);
  word-wrap: break-word;
  width: fit-content;
}

.cmp-text table {
  @include regular-lg();

  width: 100%;
  border: var(--spacing-xxxs) solid transparent;
}

.cmp-text table p {
  word-break: unset;
}

.cmp-text th {
  @include regular-lg;

  padding: var(--spacing-md) var(--spacing-lg);
  text-align: left;
  background: var(--color-gray-50);
}

.cmp-text td {
  padding: var(--spacing-md) var(--spacing-lg);
  min-width: 10rem;
}

/* Text Formats */
.cmp-text__body-copy {
  @include regular-lg();
}

.cmp-text__body-copy--lg {
  @include regular-xl();
}

.cmp-text__body-copy--sm {
  @include regular-md();
}

.cmp-text__body-copy--xs {
  @include regular-xs();
}

.cmp-text__body-copy--color-white {
  color: var(--color-white);
}

.cmp-text__body-copy--color-gray-900 {
  color: var(--color-gray-900);
}

.cmp-text__body-copy--color-cool-grey {
  color: var(--color-gray-500);
}

.cmp-text__table-wrapper {
  overflow-x: auto;
  width: 100%;
  max-width: 100%;
  margin-bottom: var(--spacing-md);
  padding: var(--spacing-xxxs) 0;

  tr th:first-child {
    background-color: var(--color-gray-50);
  }
}

.cmp-text__table-wrapper tr:hover {
  border: var(--spacing-xxxs) solid transparent;
}

.cmp-text__table--row-selectable:hover {
  outline: var(--color-primary-500) solid var(--spacing-xxxs);
  cursor: pointer;
}

.cmp-text__table--cell-selectable:hover {
  cursor: initial;
}

.cmp-text__table--row-selectable td:first-child,
.cmp-text__table--row-selectable th:first-child,
.cmp-text__table--row-selectable td:last-child,
.cmp-text__table--row-selectable th:last-child {
  position: relative;
}

.cmp-text__table--row-selectable td:first-child::before,
.cmp-text__table--row-selectable th:first-child::before,
.cmp-text__table--row-selectable td:last-child::before,
.cmp-text__table--row-selectable th:last-child::before {
  position: absolute;
  top: calc(var(--spacing-xxxs) * -1);
  bottom: calc(var(--spacing-xxxs) * -1);
  left: calc(var(--spacing-xxxs) * -1);
  width: var(--spacing-xxs);
  background: var(--color-primary-500);
  content: '';
  transition: transform 0.25s;
  transform: scaleX(0);
  transform-origin: 0 0;
}

.cmp-text__table--row-selectable td:last-child::before,
.cmp-text__table--row-selectable th:last-child::before {
  right: calc(var(--spacing-xxxs) * -1);
  width: var(--spacing-xxxs);
  left: unset;
}

.cmp-text__table--row-selectable:hover td:first-child::before,
.cmp-text__table--row-selectable:hover th:first-child::before,
.cmp-text__table--row-selectable:hover td:last-child::before,
.cmp-text__table--row-selectable:hover th:last-child::before {
  transform: scaleX(1);
}

.cmp-text tr:first-child th {
  background-color: var(--color-gray-700);
  color: var(--color-white);
}

.cmp-text__table-wrapper--overflow::after {
  content: '';
  position: absolute;
  background: linear-gradient(90deg, rgb(255 255 255 / 0%) 0%, rgb(0 0 0 / 50%) 100%);
  bottom: 0;
  width: 2.9rem;
  top: 0;
  right: 0;
}

.cmp-text__table-wrapper .cmp-text__table--link:hover {
  cursor: pointer;
}

.cmp-text__table-wrapper .cmp-text__table--link:hover a {
  color: black;
}

.cmp-text__author-message {
  @include regular-md();

  color: var(--color-gray-900);
  background-color: var(--color-author-highlight-light);
  border: var(--spacing-xxxs) solid var(--color-author-highlight-dark);
  padding: 0.5rem 1rem;
  margin: 1rem 0 0;
}

.cmp-text__table-wrapper__relative {
  position: relative;
}

@media (--breakpoint-down-md) {
  /* scroll table overflow on mobile  */
  .cmp-text {
    width: 100%;
  }
}

@media (--breakpoint-down-sm) {
  .cmp-text li {
    margin-bottom: var(--spacing-xs);
  }

  .cmp-text table {
    min-width: 50rem;
  }

  .cmp-text table caption {
    text-align: left;
  }
}

/* Big Numbers Annotation Styling */
.cmp-text__big-number {
  @include bold-2xl();

  display: inline-block;
  color: var(--color-gray-900);
  vertical-align: baseline;
  margin-top: -0.125rem;
}

/* Sortable Tables */
.cmp-text .sortable-header__wrapper {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}

.cmp-text .sortable-header__controls {
  width: 1.75rem;
  height: 2.25rem;
  display: flex;
  flex-flow: column nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin: 0 0 0 0.5rem;
}

.cmp-text .sort-icon {
  fill: var(--color-gray-300);
  padding: 0.625rem 0.4375rem 0;
  cursor: pointer;
  width: 1.6875rem;
  height: 1.125rem;
}

.cmp-text .sort-icon:hover {
  fill: var(--color-primary-500);
}

.cmp-text .sort-icon__down {
  padding: 0 0.4375rem 0.625rem;
}

.cmp-text .sortable-header--asc .sort-icon__up {
  fill: var(--color-black);
}

.cmp-text .sortable-header--desc .sort-icon__down {
  fill: var(--color-black);
}

.cmp-text__white-space-wrap {
  white-space: nowrap;
}

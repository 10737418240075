/**
 * Button - Component
 */
@include remove-grid-content('.button');

.button {
  display: inline-flex;
  vertical-align: top;
  justify-content: center;
}

.cmp-button {
  background-color: transparent;
  border: none;
  color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-l);
  min-width: 6rem; /* 96px */
  cursor: pointer;

  &:hover {
    color: var(--color-gray-900);

    .cmp-button__text:not(.cmp-cta__link) {
      text-decoration: underline;
    }
  }
}

main .cmp-button {
  color: var(--color-primary-500);
  line-height: var(--line-height);
}

main .cmp-button__link-icon {
  display: inline-flex;
  vertical-align: middle;
  font-size: var(--font-size-icon-md);
  line-height: var(--line-height-md);
}

main .cmp-button__link-icon::after {
  font-family: 'custom_materialicons', sans-serif;
  vertical-align: middle;
  content: var(--icon-keyboard_arrow_right);
  display: inline-flex;
}

.cmp-button a {
  display: inline-flex;
  align-items: center;
  font-weight: var(--font-weight-bold);
}

.cmp-button__text,
.cmp-form-button {
  @include bold-lg();
}

.cmp-button .cmp-button__icon::before {
  margin: 0;
}

.cmp-button__icon {
  font-family: 'custom_materialicons', sans-serif;
  font-size: var(--font-size-icon-md);
  line-height: var(--line-height);
  font-weight: var(--font-weight);
  vertical-align: middle;
  position: relative;
  display: inline-flex;
  margin-top: toRem(2px);
}

.cmp-button__icon::after {
  display: inline-flex;
}

.cmp-button__text,
.cmp-button__icon {
  pointer-events: none;
}

.cmp-button__icon + .cmp-button__text {
  margin: 0 var(--spacing-xxs);
}

/* Visual style - Policies */
.cmp-button--primary-positive .cmp-button,
.cmp-button--primary-positive .cmp-form-button {
  @include button-primary;
}

.cmp-button--primary-negative .cmp-button {
  @include button-primary-inverted;
}

.cmp-button--secondary-positive .cmp-button {
  @include button-secondary;
}

.cmp-button--secondary-negative .cmp-button {
  @include button-secondary-inverted;
}

/* sticky */
@media (--breakpoint-down-md) {
  .cmp-header--menu-open .cmp-header__navigation .button--sticky {
    display: none;
  }
}

.cmp-button--sticky {
  z-index: 5;
  cursor: pointer;
}

.cmp-button--primary-positive .cmp-button.cmp-button--sticky {
  padding: var(--spacing-sm);
  box-shadow: var(--box-shadow-xs);
}

.aem-AuthorLayer-Edit .pagestage .cmp-button--sticky {
  display: none;
}

.cmp-header__navigation .button--sticky .cmp-button--sticky.cmp-button--is-fixed {
  position: fixed;
  bottom: auto;
}

@media (--breakpoint-header-down-md) {
  .cmp-header-rebrand .cmp-header__navigation .cmp-button--primary-positive.button--sticky {
    display: block;
  }

  .cmp-header-rebrand .cmp-header__navigation .button--sticky .cmp-button--sticky {
    position: fixed;
    bottom: 0;
    padding: var(--spacing-sm);
    margin: var(--spacing-xs);
  }
}

.cmp-button.cmp-button--link,
.cmp-button.cmp-button--imgChat,
.cmp-button.cmp-button--chat {
  display: none;
}

.cmp-button.cmp-button--fixed {
  &.cmp-button--link,
  &.cmp-button--imgChat,
  &.cmp-button--chat {
    position: fixed;
    display: flex;
  }
}

.cmp-button.cmp-button--imgChat > img,
.cmp-button.cmp-button--chat {
  border-radius: var(--border-radius-s);
}

@media (--breakpoint-up-sm) {
  .cmp-button.cmp-button--sticky.cmp-button--fixed.cmp-button--chat {
    right: var(--spacing-xxxl);
    bottom: var(--spacing-xxxl);
  }
}

@media (--breakpoint-up-xxl) {
  .cmp-button.cmp-button--link,
  .cmp-button.cmp-button--imgChat {
    margin-right: 0;
  }
}

@media (--breakpoint-down-sm) {
  .cmp-button--sticky.cmp-button--fixed.cmp-button--chat {
    bottom: var(--spacing-lg);
    right: var(--spacing-lg);
  }

  .cmp-button.cmp-button--link,
  .cmp-button.cmp-button--imgChat {
    position: fixed;
    bottom: var(--spacing-lg);
    right: var(--spacing-lg);
    border-radius: var(--border-radius-s);
  }
}

@media #{$mq-2} {
  .aem-Grid {
    &.aem-Grid--md--12 {
      & > .aem-GridColumn {
        &.aem-GridColumn--offset--md--5 {
          &.cmp-form-button--wrapper {
            &.cmp-button--primary-positive {
              width: 100%;
              margin-left: 0;
              display: flex;
            }
          }
        }
      }
    }
  }
}

/* stylelint-disable-next-line selector-id-pattern, selector-max-id */
.cmp-button.cmp-button--imgChat:has(#ECC-ChatButton)::after {
  padding-top: 0;
}

/* Shape - Policies */
.cmp-button--round {
  background-color: var(--color-white);
  border-radius: 50%;
  height: var(--spacing-xxl); /* 40 */
  width: var(--spacing-xxl); /* 40 */
  line-height: var(--line-height-xs);
}

.button.cmp-button--round.cmp-button--round.cmp-button--round.cmp-button--round {
  width: 3rem;
  height: 3rem;
}

.cmp-button--round .cmp-button .cmp-button__icon {
  color: var(--color-black);
  margin: 0;
}

.cmp-button--round .cmp-button__text {
  display: none;
}

.cmp-button--primary-positive,
.cmp-button--primary-negative,
.cmp-button--secondary-positive,
.cmp-button--secondary-negative {
  .cmp-button,
  .cmp-form-button {
    &:hover,
    &:active {
      .cmp-button__text {
        text-decoration: none;
      }
    }
  }
}

.cmp-button--primary-positive .cmp-button__link-icon::after,
.cmp-button--primary-negative .cmp-button__link-icon::after,
.cmp-button--secondary-positive .cmp-button__link-icon::after,
.cmp-button--secondary-negative .cmp-button__link-icon::after {
  content: '';
}

/* footer */
.footer .cmp-button:hover {
  color: white;
}

.footer .cmp-button:hover .cmp-button__text {
  text-decoration: none;
}

/* backwards compatible icons */
.cmp-button__icon--arrow_down::before {
  content: var(--icon-keyboard_arrow_down);
}

.cmp-button__icon--arrow_left::before {
  content: var(--icon-keyboard_arrow_left);
}

.cmp-button__icon--arrow_right::before {
  content: var(--icon-keyboard_arrow_right);
}

.cmp-button__icon--arrow_up::before {
  content: var(--icon-keyboard_arrow_up);
}

.cmp-button__icon--login::before {
  content: var(--icon-login);
}

.cmp-button.link_external .cmp-button__icon,
.cmp-button .icon-link {
  display: none;
}

.cmp-button .icon-link {
  display: none;
}

.cmp-button.link_external .icon-link {
  display: inline-flex;
}

.cmp-button__inline-svg-icon {
  margin-right: var(--spacing-xxs);
  margin-left: calc(-1 * var(--spacing-xs));
}

.cmp-marquee {
  position: relative;
  padding: toRem(21px);
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (--breakpoint-down-md) {
    flex-direction: column;
    gap: var(--spacing-lg);
    align-items: flex-start;
  }

  &__title {
    color: var(--color-white);
    margin-bottom: var(--spacing-md);
  }

  &__description {
    color: var(--color-white);
  }

  &__button {
    @include bold-lg();
  }

  &::before {
    background-image: url('/src/main/webpack/resources/marquee-background.png');
    background-position: center;
    background-size: cover;
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    height: 100%;
    left: 0;
    width: 100%;
    bottom: 0;
  }
}

@include remove-grid-content('.title-v2');

.cmp-title-v2 {
  @media #{$mq-desktop} {
    padding-bottom: var(--spacing-xl);
  }

  @media #{$mq-mobile} {
    padding-bottom: var(--spacing-md);
  }

  @media #{$mq-6} {
    width: grid-spacing(6, 8, 7);
  }

  @media #{$mq-5} {
    width: grid-spacing(5, 8, 7);
  }

  @media #{$mq-4} {
    width: grid-spacing(4, 8, 7);
  }

  @media #{$mq-3} {
    width: grid-spacing(3, 8, 7);
  }

  .cmp-title-v2__title {
    margin-bottom: var(--spacing-xs);
  }

  .cmp-title-v2__pretitle {
    @include bold-lg();

    margin-bottom: var(--spacing-xs);
  }

  .cmp-title-v2__subtitle {
    margin-bottom: var(--spacing-md);
  }

  .cmp-title-v2__short-text {
    @include regular-lg();

    @media #{$mq-desktop} {
      margin-top: var(--spacing-lg);
    }

    @media #{$mq-mobile} {
      margin-top: var(--spacing-md);
    }
  }
}

.title-v2 {
  display: flex;
}

.cmp-countryselector {
  display: flex;
  flex-flow: column;
  gap: var(--spacing-lg);
}

.cmp-countryselector__title {
  font-size: var(--font-size-h3);
  line-height: var(--line-height-h3);
  text-align: center;
  font-weight: var(--font-weight-bold);
}

@media #{$mq-mobile} {
  .cmp-countryselector__title {
    @include bold-3xl();
  }
}

.cmp-countryselector__content {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.cmp-countryselector--link.cmp-button {
  border-radius: 0;
}

@media (--breakpoint-header-down-md) {
  .cmp-countryselector__content {
    flex-direction: column;
    align-items: center;
  }

  .cmp-countryselector__content .button {
    width: 17.625rem;
    margin-bottom: calc(var(--spacing-xxxs) * -1);
    position: relative;
  }

  .cmp-countryselector__content > .cmp-button--primary-positive > .cmp-button {
    position: absolute;
    left: 0;
    margin: 0;
  }
}

.cmp-countryselector__group {
  margin-right: var(--spacing-lg);
}

.cmp-countryselector__group.cmp-countryselector__group--hidden,
.cmp-countryselector__option--hidden.c-custom-select-option {
  display: none;
}

@media (--breakpoint-header-down-md) {
  .cmp-countryselector__group {
    margin-right: 0;
    margin-bottom: var(--spacing-md);
  }
}

.cmp-countryselector__group-title {
  @include bold-md();

  display: block;
  margin-bottom: var(--spacing-xs);
}

.cmp-countryselector .cmp-form-options {
  color: var(--color-gray-900);
  width: 17.625rem;
  margin-bottom: calc(var(--spacing-xxxs) * -1);
}

@media (--breakpoint-header-down-md) {
  .cmp-countryselector .cmp-form-options .c-custom-select-panel {
    top: auto;
    box-shadow: none;
    border: var(--spacing-xxxs) solid var(--color-gray-50);
  }
}

@media (--breakpoint-header-down-md) and (orientation: landscape) {
  .cmp-countryselector .cmp-form-options .c-custom-select-panel {
    max-height: calc(6.5 * 2.5rem);
  }
}

@media (--breakpoint-down-md) and (orientation: landscape) {
  .cmp-countryselector .cmp-form-options .c-custom-select-panel {
    max-height: calc(2.5 * 2.5rem);
  }
}

@media (--breakpoint-header-down-md) and (orientation: portrait) {
  .cmp-countryselector .cmp-form-options .c-custom-select-panel {
    max-height: calc(6.5 * 2.5rem);
  }
}

.language-selector--disabled {
  pointer-events: none;
  opacity: 0.6;
}
